import React from "react"
import { Container, Row, Col } from "reactstrap"
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const Footer = (props) => {
  return (
    <React.Fragment>
      <footer className="footer mm-none">
        <Container fluid={true}>
          <Row>
            <Col sm={6}>{props.t("_ALL_RIGHTS_RESERVED_")}  © AliGeorgia.Ge 2015</Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                  {/*Developed <i className="mdi mdi-heart text-danger"></i> <a href='https://www.linkedin.com/in/nika-dateshidze-55688018b/' target="_blank">Nika Dateshidze</a>*/}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>

      <footer className="dd-none" style={{backgroundColor:'#151B21', textAlign:'center', color:'white'}}>
        <Container fluid={true} style={{paddingTop:'10px', paddingBottom:'20px', color:'white'}}>
            <Row>
                <div className={'col-xl-4 col-lg-4 col-sm-6'}>
                    <p className="pt-2"><Link to={'/terms'} style={{color: 'white'}}>
                        {props.t("_SITE_TERMS_AND_CONDITIONS_")}  </Link>
                    </p>
                </div>
                <div className={'col-xl-4 col-lg-4 col-sm-6'}>
                    <div className={'row'} style={{fontSize: '32px'}}>
                        <div className="col">
                            <a href='https://www.facebook.com/people/AliGeorgia-%E1%83%90%E1%83%9A%E1%83%98%E1%83%AF%E1%83%9D%E1%83%A0%E1%83%AF%E1%83%98%E1%83%90/61550761802267/'
                               style={{color :'white'}}
                               target='_blank'> <i className="bx bxl-facebook-circle"></i> </a>
                        </div>
                        <div className="col">
                            <a href='https://www.instagram.com/aligeorgia.ge/'
                               style={{color :'white'}}
                               target='_blank'> <i className="bx bxl-instagram-alt"></i> </a>

                        </div>
                        <div className="col">
                            <a href='https://www.linkedin.com/company/aligeorgia/about/'
                               style={{color :'white'}}
                               target='_blank'> <i className="bx bxl-linkedin-square"></i> </a>
                        </div>
                    </div>
                </div>
            </Row>
            <Row>
                <Col sm={12}> <br/> </Col>
            </Row>
            <Row>
                <Col sm={12}>{props.t("_ALL_RIGHTS_RESERVED_")}  © AliGeorgia.Ge 2015</Col>
                {/*<Col sm={12}>  Developed <i className="mdi mdi-heart text-danger"></i> <a href='https://www.linkedin.com/in/nika-dateshidze-55688018b/' target="_blank">Nika Dateshidze</a></Col>*/}
            </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default withTranslation()(Footer);
