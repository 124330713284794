import {Button} from "reactstrap";
import React, {useEffect, useState, Fragment} from "react";
import {connect, useDispatch} from "react-redux";
import {withTranslation} from "react-i18next";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

import {showRightSidebarUpdateParcelAction} from "../../store/layout/actions";
import {deleteProduct, getProduct} from "../../store/products/actions";

import ReactTooltip from 'react-tooltip';
import {BranchAddress} from "../../helpers/types";
import SweetAlert from "react-bootstrap-sweetalert";

const TableObject = (props) => {
    const dispatch = useDispatch();
    const handleUpdateParcel = (id) => {
        let item = {
            id
        }
        dispatch(getProduct(item))
        dispatch(showRightSidebarUpdateParcelAction(true))
    }

    const handleLink = (filename) => {
        if (filename) {
            window.open(process.env.REACT_APP_UPLOADER_SERVER_URL + '/invoices/' + filename)
        }
    }


    const [confirm_alert, setconfirm_alert] = useState(false)
    const [key, setKey] = useState({})
    const [id, setId] = useState({})

    const handleOpenAlertModal = (id, key) => {
        setKey(key)
        setId(id)
        setconfirm_alert(true)
    }

    const handleDeleteParcel = () => {
        setconfirm_alert(false)
        let item = {
            id
        }
        dispatch(deleteProduct(item))
    }


    return (
        <>
            <Col xl={3} lg={4} sm={6} className="mb-2">
                {confirm_alert ? (
                    <SweetAlert
                        title="დარწმუნებული ხართ?"
                        warning
                        showCancel
                        confirmButtonText="Yes, delete it!"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={handleDeleteParcel}
                        onCancel={() => setconfirm_alert(false)}
                    >
                        რომ გსურთ წაშალოთ?!
                    </SweetAlert>
                ) : null}
            </Col>
            {
                props.Products.array.map((item, key) => {
                    return (
                        <Fragment key={key}>
                            {
                                props.country === item.senderCountryId
                                    ?
                                    <div style={{paddingTop:'5px'}}>
                                        <div className={'traking-box '}>
                                            <Row>
                                                <Col>{props.t("_WAYBILL_NUMBER_")} </Col>
                                                <Col>{item.productOrderId}</Col>
                                            </Row>
                                            <Row>
                                                <Col>{props.t("_PRODUCT_NAME_")} </Col>
                                                <Col>{props.t(item.productName)} </Col>
                                            </Row>
                                            <Row>
                                                <Col>{props.t("_QUANTITY_")} </Col>
                                                <Col>   {item.quantity}</Col>
                                            </Row>
                                            <Row>
                                                <Col>{props.t("_ONLINE_MARKET_")} </Col>
                                                <Col>   {
                                                    item.personalParcel
                                                        ? <>
                                                            პერსონალური - <br/>
                                                            {item.senderName}
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                item.shop === '_OTHER_SHOP_'
                                                                    ? item.otherShop
                                                                    : item.shop
                                                            }
                                                        </>
                                                }</Col>
                                            </Row>
                                            <Row>
                                                <Col>{props.t("_PAID_AMOUNT_")} </Col>
                                                <Col>   {item.price}</Col>
                                            </Row>
                                            <Row>
                                                <Col>{props.t("_CURRENCY_")} </Col>
                                                <Col>  {item.currency}</Col>
                                            </Row>
                                            <Row>
                                                <Col>{props.t("_branch")} </Col>
                                                <Col>  {BranchAddress(item.branch,props)}</Col>
                                            </Row>
                                            <Row>
                                                <Col>{props.t("_INVOICE_")} </Col>
                                                <Col>
                                                    {
                                                        item.file
                                                            ?
                                                            <Button
                                                                onClick={handleLink.bind(this, item.file)}
                                                                type="button" color="success" style={{width: '100%'}}
                                                                className="btn-sm waves-effect waves-light">
                                                                {props.t("_FILE_")}
                                                            </Button>
                                                            : null
                                                    }

                                                    {
                                                        item.description
                                                            ?
                                                            <>
                                                                <div style={{paddingTop: '5px'}}>
                                                                    <p data-tip={item.description}>
                                                                        <Button type="button" color="primary"
                                                                                style={{width: '100%'}}
                                                                                className="btn-sm waves-effect waves-light">
                                                                            {props.t("_COMMENT_")}
                                                                        </Button>
                                                                    </p>
                                                                    <ReactTooltip/>
                                                                </div>
                                                            </>

                                                            : null
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> </Col>
                                                <Col>
                                                    {
                                                        props.status === 'arrived' || props.status === 'obtained'
                                                            ? null
                                                            : <Button
                                                                onClick={handleUpdateParcel.bind(this, item._id)}
                                                                type="button" color={item.quantity ? 'warning' : 'danger'}
                                                                className="btn-sm btn-rounded waves-effect waves-light btn-min-wi">

                                                                {
                                                                    item.quantity
                                                                        ? <> {props.t("_EDIT_")}</>
                                                                        : <> {props.t("_DECLARE_")}</>
                                                                }

                                                            </Button>
                                                    }
                                           {/*         {
                                                        props.status === 'waiting'
                                                        && <Button
                                                            onClick={() => {
                                                                handleOpenAlertModal(item._id)
                                                            }}
                                                            type="button" color={'danger'}
                                                            className="btn-sm btn-rounded" style={{marginLeft:'10px'}}>
                                                            <i className="uil uil-trash-alt font-size-18"></i>
                                                        </Button>
                                                    }
*/}
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    : null
                            }

                        </Fragment>
                    )
                })
            }

        </>

    )
}


const mapStateToProps = state => {
    const {
        Layout,
        Products
    } = state
    return {
        Layout,
        Products
    }
};

export default connect(mapStateToProps)(withTranslation()(TableObject));
