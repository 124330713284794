import {
    BTN_SPINNER_ACTIVE, DELETE_PRODUCTS_REQUEST_SUCCESS,
    GET_PRODUCT_REQUEST_SUCCESS,
    GET_PRODUCTS_INTINITI_REQUEST_OBTAINET_SUCCESS,
    GET_PRODUCTS_INTINITI_REQUEST_SUCCESS,
    GET_PRODUCTS_REQUEST_OBTAINET_SUCCESS,
    GET_PRODUCTS_REQUEST_SUCCESS,
    GET_PRODUCTS_WITH_FLIGHT_ID_REQUEST_SUCCESS,
    NULL_PRODUCTS,
    PRODUCT_NULL_REQUEST, PRODUCT_ONE_NULL_REQUEST,
    UPDATE_PRODUCT_DECLARATION_REQUEST_SUCCESS,
    UPDATE_PRODUCT_DECLARATION_REQUEST_WITH_FILE_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    array : [],
    newArr : [],
    flights : [],
    product : {},
    productsCount : 0,
    productsCountChina : 0,
    productsCountUsa : 0,
    productsCountTurkey : 0,
    productsCountGrecee : 0,
    giveawayFlight : [],
    isLoad : true,
    btnIsLoad : false,

    arrayObtainet : [],
    productsCountObtainet : 0,
    productsCountChinaObtainet : 0,
    productsCountUsaObtainet : 0,
    productsCountTurkeyObtainet : 0,
    productsCountGreceeObtainet : 0,
    giveawayFlightObtainet : [],
    isLoadObtainet : true,
    btnIsLoadObtainet : false,

}

const Products = (state = INIT_STATE, action) => {
    switch (action.type) {

        case DELETE_PRODUCTS_REQUEST_SUCCESS:
            console.log("@@@@@@@@@@@@@DELETE_PRODUCTS_REQUEST_SUCCESS@@@@@",action.payload.id)
            return {
                ...state,
                array: state.array.filter(todo => todo._id !== action.payload.id)
            }


        case GET_PRODUCTS_REQUEST_SUCCESS:
            return {
                ...state,
                array: action.callBack.products,
                productsCount: action.callBack.productsCount,
                productsCountChina: action.callBack.productsCountChina,
                productsCountUsa: action.callBack.productsCountUsa,
                productsCountTurkey: action.callBack.productsCountTurkey,
                productsCountGrecee: action.callBack.productsCountGrecee,
                giveawayFlight :action.callBack.newArr,
                isLoad :false,
                btnIsLoad :false
            }
        case GET_PRODUCTS_REQUEST_OBTAINET_SUCCESS:
            return {
                ...state,
                arrayObtainet: action.callBack.products,
                productsCountObtainet: action.callBack.productsCount,
                productsCountChinaObtainet: action.callBack.productsCountChina,
                productsCountUsaObtainet: action.callBack.productsCountUsa,
                productsCountTurkeyObtainet: action.callBack.productsCountTurkey,
                productsCountGreceeObtainet: action.callBack.productsCountGrecee,
                giveawayFlightObtainet :action.callBack.newArr,
                isLoadObtainet :false,
                btnIsLoadObtainet :false
            }
        case GET_PRODUCTS_INTINITI_REQUEST_SUCCESS:
            return {
                ...state,
                array: [...state.array, ...action.callBack.products] ,
                productsCount: action.callBack.productsCount,
                productsCountChina: action.callBack.productsCountChina,
                productsCountUsa: action.callBack.productsCountUsa,
                productsCountTurkey: action.callBack.productsCountTurkey,
                productsCountGrecee: action.callBack.productsCountGrecee,
                giveawayFlight :action.callBack.newArr,
                isLoad :false,
                btnIsLoad :false
            }

        case GET_PRODUCTS_INTINITI_REQUEST_OBTAINET_SUCCESS:
            return {
                ...state,
                arrayObtainet: [...state.arrayObtainet, ...action.callBack.products],
                productsCountObtainet: action.callBack.productsCount,
                productsCountChinaObtainet: action.callBack.productsCountChina,
                productsCountUsaObtainet: action.callBack.productsCountUsa,
                productsCountTurkeyObtainet: action.callBack.productsCountTurkey,
                productsCountGreceeObtainet: action.callBack.productsCountGrecee,
                giveawayFlightObtainet :[...state.giveawayFlightObtainet, ...action.callBack.newArr],
                isLoadObtainet :false,
                btnIsLoadObtainet :false
            }
        case GET_PRODUCTS_WITH_FLIGHT_ID_REQUEST_SUCCESS:
            return {
                ...state,
                array: action.callBack.products,
                newArr: action.callBack.newArr,
                flights: action.callBack.flights,
            }

        case GET_PRODUCT_REQUEST_SUCCESS:
            return {
                ...state,
                product: action.callBack.product,
            }
        case BTN_SPINNER_ACTIVE:
            return {
                ...state,
                btnIsLoad : action.payload.active,
                btnIsLoadObtainet : action.payload.active,
            }

        case PRODUCT_ONE_NULL_REQUEST:
            return {
                ...state,
                product : {}
            }

        case PRODUCT_NULL_REQUEST:
            return {
                ...state,
                array : [],
                newArr : [],
                flights : [],
                product : [],
                productsCount: 0,
                productsCountChina : 0,
                productsCountUsa : 0,
                productsCountTurkey : 0,
                productsCountGrecee : 0,
                giveawayFlight : [],
            }

        case UPDATE_PRODUCT_DECLARATION_REQUEST_SUCCESS:

            if (action.payloading.callBack.customArray.length > 1) {
                    for (let a = 0; a< action.payloading.callBack.customArray.length; a++) {
                        state.array.map((item, key)=>{
                            if (action.payloading.callBack.customArray[a].productOrderId === item.productOrderId) {
                                state.array[key].custom = action.payloading.callBack.custom
                                state.array[key].clearance = action.payloading.callBack.custom
                            }
                        })
                    }

                state.array.map((item) =>{

                    if (item._id === action.payloading.payload.productId) {
                        item.personalParcel = action.payloading.payload.personalParcel
                        // item.clearance = action.payloading.payload.clearance
                        // item.custom = action.payloading.callBack.custom
                        item.productOrderId = action.payloading.payload.productOrderId
                        item.productName = action.payloading.payload.productName
                        item.quantity = action.payloading.payload.quantity
                        item.shop = action.payloading.payload.shop
                        item.price = action.payloading.payload.amount
                        item.currency = action.payloading.payload.currency
                        item.description = action.payloading.payload.description
                    }
                })
                return {
                    ...state,
                }
            }else {
                state.array.map((item) =>{
                    if (item.productOrderId === action.payloading.payload.productOrderId) {
                        if (action.payloading.payload.am >= 300 || action.payloading.payload.clearance === true || action.payloading.callBack.custom) {
                            item.custom = true
                        }else {
                            item.custom = false
                        }
                        item.personalParcel = action.payloading.payload.personalParcel
                        // item.clearance = action.payloading.payload.clearance
                        item.productOrderId = action.payloading.payload.productOrderId
                        item.productName = action.payloading.payload.productName
                        item.quantity = action.payloading.payload.quantity
                        item.shop = action.payloading.payload.shop
                        item.price = action.payloading.payload.amount
                        item.currency = action.payloading.payload.currency
                        item.description = action.payloading.payload.description
                    }
                })
                return {
                    ...state,
                }
            }


        case UPDATE_PRODUCT_DECLARATION_REQUEST_WITH_FILE_SUCCESS:
            if (action.payloading.callBack.customArray.length > 0) {
                for (let a = 0; a< action.payloading.callBack.customArray.length; a++) {
                    state.array.map((item, key)=>{
                        if (action.payloading.callBack.customArray[a]._id === item._id) {
                            state.array[key].custom = action.payloading.callBack.custom
                            state.array[key].clearance = action.payloading.callBack.custom
                        }
                    })
                }
            }
            state.array.map((item) =>{

                if (item._id === action.payloading.callBack.product._id) {
                    item.personalParcel = action.payloading.callBack.product.personalParcel
                    item.clearance = action.payloading.callBack.product.clearance
                    item.custom = action.payloading.callBack.custom
                    item.productOrderId = action.payloading.callBack.product.productOrderId
                    item.productName = action.payloading.callBack.product.productName
                    item.quantity = action.payloading.callBack.product.quantity
                    item.shop = action.payloading.callBack.product.shop
                    item.price = action.payloading.callBack.product.price
                    item.currency = action.payloading.callBack.product.currency
                    item.description = action.payloading.callBack.product.description
                    item.file = action.payloading.callBack.product.file
                }
            })
            return {
                ...state,
            }

        default:
            return state
    }
}

export default Products
